import { useEffect, useState } from "react"
import { useAuthContext } from "../hocs/AuthProvider"
import { useSnackbarContext } from "../hocs/SnackbarProvider"

const filtersToParams = (filters, params, fields) => {
  const selectsFields = fields.selects.map(s => s.id.toString())
  for (const [key, value] of Object.entries(filters)) {
    if (selectsFields.includes(key)){
      if (value && value !== -1) params.append(key.toString(), value)
    }else{
        if (key !== "from_new_customer"){
          if (key !== "no_order"){
            if (value){
              params.append('results[]', key)
            }
          }
        }
    }
  }
  return params
}

const getCustomersRequest = async (headers, showSnackbar, startDate, endDate, fields, filters, url) => {
  let params = new URLSearchParams({
    start_date: startDate.format('YYYY-MM-DD'),
    end_date: endDate.format('YYYY-MM-DD'),
    // page: page + 1,
    // page_size: perPage,
  })
  if (filters.from_new_customer){
    params.append("from_new_customer", 1)
  }
  if (filters.no_order){
    params.append("no_order", 1)
  }
  if (filters && fields) params = filtersToParams(filters, params, fields)
  const res = await fetch(process.env.REACT_APP_API_URL + `${url}?` + params.toString(), {
    method: 'GET',
    headers: headers
  }).catch(e => {
    alert('Ошибка ' + e.name + ":" + e.message + "\n" + e.stack); 
  })
  if (res.status === 403) {
    showSnackbar("Недостаточно прав доступа")
  }
  if (!res?.ok) return false
  return await res.json()
}

export default function useCounter (startDate, endDate, fields, filters, url){
  const [customers, setCustomers] = useState(false)
  const { getAuthHeader } = useAuthContext()
  const { showSnackbar } = useSnackbarContext()

  useEffect(() => {
    setCustomers(false)
    const load = async () => {
      const headers = await getAuthHeader()
      const res = await getCustomersRequest(headers, showSnackbar, startDate, endDate, fields, filters, url)
      setCustomers(res ? res : false)
    }
    load()
    setCustomers(false)
  }, [filters, startDate, endDate])
  return { customers }
}