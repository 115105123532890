import {
  Button,
  Checkbox,
  Select,
  Box,
  useTheme,
  useMediaQuery,
  Skeleton,
  Typography,
  ButtonGroup,
  Grid,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
  Tooltip,
  ListItemText,
  OutlinedInput,
} from "@mui/material"
import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"

const Loading = () => {
  return (
    <Grid container spacing={2}>
      {[...Array(10).keys()].map((it) => (
        <Grid item xs={6} sm={4} md={3} lg={3} key={it}>
          <Typography variant="h6">
            <Skeleton />
          </Typography>
        </Grid>
      ))}
    </Grid>
  )
}

const SelectsList = ({ selects, size, control }) => {
  return (
    <Grid container spacing={2}>
      {selects.map((s) => (
        <Grid item key={s.id} xs={12} sm={6} md={6} lg={6}>
          <FormControl
            sx={{ width: "100%", boxSizing: "border-box" }}
            size={size}
          >
            <InputLabel
              variant={"outlined"}
              id={`${s.id}-filter-select-label-id`}
            >
              {s.title}
            </InputLabel>
            <Controller
              defaultValue={[-1]}
              render={({ field }) => (
                <Select
                  label={s.title}
                  sx={{ width: "100%", minWidth: "100%" }}
                  variant="outlined"
                  size={size}
                  labelId={`${s.id}-filter-select-label-id`}
                  {...field}
                  multiple
                  // input={<OutlinedInput label="Tag" />}
                  // renderValue={(selected) =>
                  //   selected.map((x) => x.name).join(", ")
                  // }
                >
                  {s.options.map((op) => (
                    <MenuItem key={op.id} value={op.id}>
                      {op?.name ? op.name : op?.title ? op.title : "-"}
                    </MenuItem>
                  ))}
                  {/* {s.options.map((op) => (
                    <MenuItem key={op.id} value={op.id}>
                      <Checkbox />
                      <ListItemText
                        primary={
                          op?.name ? op.name : op?.title ? op.title : "-"
                        }
                      />
                    </MenuItem>
                  ))} */}
                </Select>
              )}
              name={s.id}
              control={control}
            />
          </FormControl>
        </Grid>
      ))}
    </Grid>
  )
}

const CheckboxsList = ({ checkboxs, size, control, checkboxsStats }) => {
  return (
    <Grid container spacing={0}>
      {checkboxs.map((c) => (
        <Grid item key={c.id} xs={6} sm={4} md={4} lg={4} xl={4}>
          <FormControlLabel
            label={`${c.title}: ${
              checkboxsStats && checkboxsStats[c.id]
                ? checkboxsStats[c.id].count +
                  ` (${Math.round(
                    // (checkboxsStats[c.id].count * 100) / checkboxsStats["total"]
                    checkboxsStats[c.id].percentage
                  )}%)`
                : "-"
            }
            `}
            defaultChecked={false}
            control={
              <Controller
                defaultValue={false}
                name={c.id}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    size={size}
                    checked={field["value"] ?? false}
                  />
                )}
                control={control}
              />
            }
          />
        </Grid>
      ))}
    </Grid>
  )
}

const Content = ({
  size,
  fields,
  onApplyFilter,
  onResetFilter,
  count,
  checkboxsStats,
  aiStats = false,
}) => {
  const { reset, handleSubmit, control } = useForm({ mode: "onBlur" })

  const onClickReset = () => {
    reset()
    onResetFilter()
  }

  return (
    <form onSubmit={handleSubmit(onApplyFilter)}>
      <Box
        sx={{
          display: "flex",
          flexFlow: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "15px",
        }}
      >
        {count !== undefined && (
          <Typography
            sx={{ width: "100%", fontWeight: 600 }}
            variant="h6"
          >{`Всего звонков: ${count ? count : "-"}`}</Typography>
        )}
        {checkboxsStats?.total_listened && (
          <Typography variant="h6" sx={{ width: "100%", fontWeight: 600 }}>{`
          Всего прослушано: (${checkboxsStats.total_listened.percentage}%) ${checkboxsStats.total_listened.count}`}</Typography>
        )}
        {aiStats && (
          <Typography variant="h6" sx={{ width: "100%", fontWeight: 600 }}>{`
          Всего обработано (ИИ): (${aiStats.total_processed.percentage}%) ${aiStats.total_processed.count}`}</Typography>
        )}
        {aiStats && aiStats?.new_calls && aiStats.new_calls ? (
          <Typography variant="h6" sx={{ width: "100%", fontWeight: 600 }}>{`
            Новые звонки (ИИ): (${aiStats.new_calls.percentage}%) ${aiStats.new_calls.count}`}</Typography>
        ) : (
          <></>
        )}
        <Typography sx={{ width: "100%", fontWeight: 600 }} variant="h6">
          {`Исходящие`}
          <Controller
            defaultValue={false}
            name={"only_outcoming"}
            render={({ field }) => (
              <Tooltip title={"Отображать исходящие звонки"}>
                <Checkbox
                  {...field}
                  size={size}
                  checked={field["value"] ?? false}
                />
              </Tooltip>
            )}
            control={control}
          />
        </Typography>
        <Typography sx={{ width: "100%", fontWeight: 600 }} variant="h6">
          {`Давно не было`}
          <Controller
            defaultValue={false}
            name={"no_recent_order"}
            render={({ field }) => (
              <Tooltip title={"Клиенты которых давно не было"}>
                <Checkbox
                  {...field}
                  size={size}
                  checked={field["value"] ?? false}
                />
              </Tooltip>
            )}
            control={control}
          />
        </Typography>
        {checkboxsStats?.new_calls ? (
          <Typography sx={{ width: "100%", fontWeight: 600 }} variant="h6">
            {`Новые звонки: (${checkboxsStats.new_calls.percentage}%) ${checkboxsStats.new_calls.count}`}
            <Controller
              defaultValue={false}
              name={"from_new_customer"}
              render={({ field }) => (
                <Tooltip title={"Отображать звонки только от новых клиентов"}>
                  <Checkbox
                    {...field}
                    size={size}
                    checked={field["value"] ?? false}
                  />
                </Tooltip>
              )}
              control={control}
            />
          </Typography>
        ) : (
          <></>
        )}
        {checkboxsStats?.no_order ? (
          <Typography sx={{ width: "100%", fontWeight: 600 }} variant="h6">
            {`Нет заказов: (${checkboxsStats.no_order.percentage}%) ${checkboxsStats.no_order.count}`}
            <Controller
              defaultValue={false}
              name={"no_order"}
              render={({ field }) => (
                <Tooltip
                  title={"Отображать звонки только от клиентов без заказов"}
                >
                  <Checkbox
                    {...field}
                    size={size}
                    checked={field["value"] ?? false}
                  />
                </Tooltip>
              )}
              control={control}
            />
          </Typography>
        ) : (
          <></>
        )}
        {checkboxsStats?.not_listened ? (
          <Typography sx={{ width: "100%", fontWeight: 600 }} variant="h6">
            {`Не прослушано: (${checkboxsStats.not_listened.percentage}%) ${checkboxsStats.not_listened.count}`}
            <Controller
              defaultValue={false}
              name={"not_listened"}
              render={({ field }) => (
                <Tooltip title={"Звонки которые не прослушаны"}>
                  <Checkbox
                    {...field}
                    size={size}
                    checked={field["value"] ?? false}
                  />
                </Tooltip>
              )}
              control={control}
            />
          </Typography>
        ) : (
          <></>
        )}
        <Grid container spacing={1}>
          {fields?.checkboxs && fields.checkboxs.length && (
            <Grid item lg={6} xl={6}>
              <CheckboxsList
                control={control}
                checkboxs={fields.checkboxs}
                size={size}
                checkboxsStats={checkboxsStats}
              />
            </Grid>
          )}
          {fields?.selects && fields.selects.length && (
            <Grid item lg={6} xl={6}>
              <SelectsList
                control={control}
                selects={fields.selects}
                size={size}
              />
            </Grid>
          )}
        </Grid>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-start",
            flexFlow: "row nowrap",
            alignItems: "center",
          }}
        >
          <ButtonGroup size={size} variant="contained">
            <Button type="submit" color="primary">
              Применить
            </Button>
            <Button onClick={onClickReset} color="secondary">
              Сбросить
            </Button>
          </ButtonGroup>
        </Box>
      </Box>
    </form>
  )
}

export default function Filter({
  fields = false,
  onApplyFilter,
  onResetFilter,
  count,
  checkboxsStats,
  aiStats = false,
}) {
  const theme = useTheme()
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"))
  // const isMdScreen = useMediaQuery(theme.breakpoints.down('md'))
  const size = isSmScreen ? "small" : "medium"

  return (
    <Box
      p={isSmScreen ? 2 : 4}
      sx={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        backgroundColor: "#fff",
        borderRadius: "15px",
      }}
    >
      {fields ? (
        <Content
          size={size}
          fields={fields}
          onApplyFilter={onApplyFilter}
          onResetFilter={onResetFilter}
          count={count}
          checkboxsStats={checkboxsStats}
          aiStats={aiStats}
        />
      ) : (
        <Loading />
      )}
    </Box>
  )
}
