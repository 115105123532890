import React, { useState } from "react"
import {
  Box,
  Button,
  IconButton,
  InputLabel,
  List,
  ListItem,
  Select,
  Skeleton,
  Typography,
  MenuItem,
  FormControl,
  useMediaQuery,
  useTheme,
  Grid,
} from "@mui/material"
import Header from "../../components/Header"
import useTasks from "../../hooks/useTasks"
import TaskModal from "../../components/TaskModal"
import { MoreHorizRounded, ThumbUpAltOutlined } from "@mui/icons-material"
import { useOutletContext } from "react-router-dom"
import { useForm, Controller } from "react-hook-form"
// import { Task } from "entities/task"
// import { NotData } from "widgets/task/ui/not-data"
// import { Loading } from "widgets/task/ui/loading"

const Loading = () => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexFlow: "column",
        // gap: "5px",
        padding: "30px",
        overflow: "hidden",
        boxSizing: "border-box",
      }}
    >
      {[...Array(3)].map((e, index) => (
        <Typography
          key={index}
          variant="h3"
          sx={{ width: "100%", boxSizing: "border-box" }}
        >
          <Skeleton />
        </Typography>
      ))}
    </Box>
  )
}

const NotTasks = ({ createTask }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "15px",
        width: "100%",
        height: "100%",
      }}
    >
      <Typography variant="h6" align="center">
        Продуктивно :D <br />
        Активных задач нет
      </Typography>
      <ThumbUpAltOutlined />
    </Box>
  )
}

const Task = ({
  task: { id, description, title, status, created_at },
  fields,
  setTaskModal,
  onChangeStatus,
}) => {
  const theme = useTheme()
  const isSmScreen = useMediaQuery(theme.breakpoints.down("md"))
  let sliced = title.slice(0, isSmScreen ? 12 : 50)
  if (sliced.length < title.length) {
    sliced += "..."
  }

  return (
    <ListItem
      sx={{
        width: "100%",
        border: "1px #ccc solid",
        borderRadius: "5px",
        cursor: "pointer",
        ":hover": {
          background: "#ddd",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
        onClick={setTaskModal}
      >
        <Typography variant="span">{sliced}</Typography>
        {created_at && <Typography variant="span">{created_at}</Typography>}
        <Box
          sx={{
            height: "auto",
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: isSmScreen ? "5px" : "15px",
          }}
        >
          <FormControl>
            <InputLabel variant={"outlined"} id={`task-select-label-${id}`}>
              {"Статус"}
            </InputLabel>
            <Select
              label={"Статус"}
              defaultValue={status || "open"}
              sx={{ width: "150px" }}
              variant="outlined"
              size={"small"}
              labelId={`task-select-label-${id}`}
              onChange={(e) => onChangeStatus(e.target.value, id)}
            >
              <MenuItem value={-1}>Не выбрано</MenuItem>
              {fields.results.map((op) => (
                <MenuItem key={op.pk} value={op.pk}>
                  {op.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <IconButton>
            <MoreHorizRounded />
          </IconButton>
        </Box>
      </Box>
    </ListItem>
  )
}

const TasksList = ({
  tasks,
  setTasks,
  setTaskModal,
  createTask,
  fields,
  onChangeStatus,
  pagePadding,
}) => {
  if (tasks && fields) {
    if (tasks.count === 0) return <NotTasks createTask={createTask} />
    return (
      <List
        sx={{
          width: "100%",
          display: "flex",
          flexFlow: "column",
          gap: "5px",
          boxSizing: "border-box",
          padding: pagePadding,
        }}
      >
        {tasks.results.map((t) => (
          <Task
            key={t.id}
            task={t}
            fields={fields}
            setTaskModal={() => setTaskModal(t)}
            onChangeStatus={onChangeStatus}
          />
        ))}
      </List>
    )
  } else {
    return <Loading />
  }
}

const Filter = ({ createTask, size, fields = false, setFilters }) => {
  size = "small"
  if (!fields)
    fields = {
      results: [],
      departments: [],
    }
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ mode: "onBlur" })

  const onSubmit = (d) => setFilters(d)

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        boxSizing: "border-box",
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <form onBlur={handleSubmit(onSubmit)}>
        {/* <Box
          sx={{
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "15px",
          }}
        > */}
        <Grid container spacing={2}>
          <Grid item>
            <FormControl
              sx={{ width: "100%", boxSizing: "border-box" }}
              size={size}
            >
              <InputLabel
                variant={"outlined"}
                id={`taks-modal-select-label-id`}
              >
                {"Статус"}
              </InputLabel>
              <Controller
                defaultValue={-1}
                render={({ field }) => (
                  <Select
                    label={"Статус"}
                    sx={{ width: "200px", boxSizing: "border-box" }}
                    variant="outlined"
                    size={size}
                    labelId={`task-modal-select-label-id`}
                    {...field}
                  >
                    <MenuItem value={-1}>Все</MenuItem>
                    {fields.results.map((op) => (
                      <MenuItem key={op.pk} value={op.pk}>
                        {op.title}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                name={"status"}
                control={control}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl
              sx={{ width: "100%", boxSizing: "border-box" }}
              size={size}
            >
              <InputLabel
                variant={"outlined"}
                id={`taks-modal-select-label-id`}
              >
                {"Департамент"}
              </InputLabel>
              <Controller
                defaultValue={-1}
                render={({ field }) => (
                  <Select
                    label={"Департамент"}
                    sx={{ width: "200px", boxSizing: "border-box" }}
                    variant="outlined"
                    size={size}
                    labelId={`task-modal-select-label-id`}
                    {...field}
                  >
                    <MenuItem value={-1}>Все</MenuItem>
                    {/* {fields.departments.map((op) => (
                      <MenuItem key={op.id} value={op.id}>
                        {op.title}
                      </MenuItem>
                    ))} */}
                  </Select>
                )}
                name={"department"}
                control={control}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl
              sx={{ width: "100%", boxSizing: "border-box" }}
              size={size}
            >
              <InputLabel
                variant={"outlined"}
                id={`taks-modal-select-label-id`}
              >
                {"Исполнитель"}
              </InputLabel>
              <Controller
                defaultValue={-1}
                render={({ field }) => (
                  <Select
                    label={"Исполнитель"}
                    sx={{ width: "200px", boxSizing: "border-box" }}
                    variant="outlined"
                    size={size}
                    labelId={`task-modal-select-label-id`}
                    {...field}
                  >
                    <MenuItem value={-1}>Все</MenuItem>
                    {/* {fields.results.map((op) => (
                      <MenuItem key={op.pk} value={op.pk}>
                        {op.title}
                      </MenuItem>
                    ))} */}
                  </Select>
                )}
                name={"executor"}
                control={control}
              />
            </FormControl>
          </Grid>
        </Grid>
        {/* </Box> */}
      </form>
      <Button
        onClick={createTask}
        sx={{ minWidth: "100px" }}
        variant="contained"
      >
        Создать
      </Button>
    </Box>
  )
}

export default function Tasks() {
  const [taskModal, setTaskModal] = useState(false)
  const { tasks, setTasks, fields, setFilters, saveTask, createTask } =
    useTasks()
  const pagePadding = useOutletContext()
  const theme = useTheme()
  const isSmScreen = useMediaQuery(theme.breakpoints.down("md"))
  const size = isSmScreen ? "small" : "medium"

  const onChangeStatus = (v, taskId) => {
    saveTask({ id: taskId, status: v })
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        overflow: "hidden",
        display: "flex",
        flexFlow: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: pagePadding,
      }}
    >
      {taskModal && (
        <TaskModal
          onClose={() => setTaskModal(false)}
          task={taskModal}
          setTasks={setTasks}
          fields={fields}
          saveTask={saveTask}
          createTask={createTask}
        />
      )}
      <Header header={"Задачи"} />
      <Filter
        fields={fields}
        setFilters={setFilters}
        size={size}
        createTask={() => setTaskModal("create")}
      />
      <Box
        sx={{
          width: "100%",
          height: "100%",
          overflox: "auto",
          background: "#fff",
          boxSizing: "border-box",
          borderRadius: "15px",
          overflow: "auto",
        }}
      >
        <TasksList
          tasks={tasks}
          setTasks={setTasks}
          setTaskModal={setTaskModal}
          createTask={createTask}
          fields={fields}
          onChangeStatus={onChangeStatus}
          pagePadding={pagePadding}
        />
      </Box>
    </Box>
  )
}
