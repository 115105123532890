import React, { createRef, useEffect, useState } from "react"
import {
  Box,
  Button,
  Pagination,
  Rating,
  Skeleton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useOutletContext } from "react-router-dom"
import Header from "../../../components/Header"
import ClientFilter from "components/ClientFilter/ui/client-filter"
import useCustomers from "hooks/useCustomers"
import dayjs from "dayjs"
import { FamilyRestroomTwoTone } from "@mui/icons-material"
import useFilters from "hooks/useFilters"

const ClientCard = ({ customer }) => {
  return (
    <Box
      p={2}
      sx={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        borderRadius: "15px",
        boxShadow: "0px 10px 60px rgba(226, 236, 249, 0.5)",
        overflow: "hidden",
        background: "#fff",
      }}
    >
      <Stack direction={"column"} gap={1}>
        <Typography variant="h5">{`ФИО: ${customer?.name || "-"}`}</Typography>
        <Typography variant="h6">{`Телефон: ${
          customer?.phone || "-"
        }`}</Typography>
        <Typography variant="span">{`Email: ${
          customer?.email || "-"
        }`}</Typography>
        <Typography variant="span">{`Дата рождения: ${
          customer?.date_of_birth || "-"
        }`}</Typography>
        <Typography variant="span">{`LTV: ${
          customer?.lifetime_value || "-"
        } ₽`}</Typography>
        <Typography variant="span" alignItems={"center"}>
          {`Рейтинг: ${customer?.feedback_rate || "-"}`}
          {customer?.feedback_rate ? (
            <Rating
              sx={{ margin: 1 }}
              readOnly
              defaultValue={5.0}
              value={customer.feedback_rate || 0.0}
            />
          ) : (
            <></>
          )}
        </Typography>
        <Stack
          gap={1}
          sx={{ width: "100%" }}
          direction={"column"}
          alignItems={"flex-end"}
          justifyContent={"center"}
        >
          <TextField
            sx={{ width: "100%" }}
            multiline
            // {...register("comment", {
            //   maxLength: {
            //     value: 1200,
            //     message: "Максимум 1200 символов",
            //   },
            //   value: call.result?.comment ? call.result.comment : undefined,
            // })}
            label={"Комментарий"}
            // error={errors?.comment}
            // helperText={errors?.comment?.message}
          ></TextField>
          <Button variant="contained">Сохранить</Button>
        </Stack>
      </Stack>
    </Box>
  )
}

const Loading = () => (
  <Box
    p={2}
    sx={{
      width: "100%",
      height: "100%",
      boxSizing: "border-box",
      borderRadius: "15px",
      boxShadow: "0px 10px 60px rgba(226, 236, 249, 0.5)",
      overflow: "hidden",
    }}
  >
    <Skeleton>
      <Typography variant="h5">{""}</Typography>
    </Skeleton>
    <Skeleton>
      <Typography variant="h6">{""}</Typography>
    </Skeleton>
    <Skeleton>
      <Typography variant="span">{"LTV: 10 000"}</Typography>
    </Skeleton>
    <Skeleton>
      <Typography variant="span">{"Последний филиал: Фучика"}</Typography>
    </Skeleton>
  </Box>
)

const Clients = ({ customers, page, setPage, setCustomers }) => {
  const theme = useTheme()
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const listRef = createRef()
  const scrollToTop = () => listRef.current && listRef.current.scrollIntoView()
  const onChangePage = (e, p) => {
    setCustomers(false)
    setPage(p)
    scrollToTop()
  }
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
      }}
    >
      <Stack direction={"column"} p={0} gap={2} ref={listRef}>
        {!customers ? (
          [...Array(6)].map((i) => <Loading key={i} />)
        ) : customers.count ? (
          <>
            {customers.results.map((c) => (
              <ClientCard customer={c} />
            ))}
            <Pagination
              page={page}
              onChange={onChangePage}
              count={Math.ceil(customers.count / 10)}
            />
          </>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              background: "#fff",
              borderRadius: "15px",
              padding: 2,
            }}
          >
            <Typography
              sx={{ width: "100%" }}
              variant="h6"
              textAlign={"center"}
            >
              Не найдено
            </Typography>
          </Box>
        )}
      </Stack>
    </Box>
  )
}

export default function ClientsPage() {
  const [page, setPage] = useState(1)
  const [rating, setRating] = useState([0.0, 5.0])
  const [LTV, setLTV] = useState([0, 1_000_000])
  const [sort, setSort] = useState(1)
  const [department, setDepartment] = useState(-1)
  const [startDate, setStartDate] = useState(dayjs(new Date()))
  const [endDate, setEndDate] = useState(dayjs(new Date()))
  const [phone, setPhone] = useState(false)
  const { fields } = useFilters("selects/calls/", false)
  const { customers, setCustomers } = useCustomers(
    startDate,
    endDate,
    page,
    LTV,
    rating,
    sort,
    phone?.phone || false,
    department
  )
  const pagePadding = useOutletContext()

  useEffect(() => {
    setPage(1)
  }, [rating, LTV])

  return (
    <>
      <Box
        sx={{
          flexFlow: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          display: "flex",
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
          gap: pagePadding,
        }}
      >
        <Header
          header="Клиенты"
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
        <ClientFilter
          fields={fields}
          LTV={LTV}
          setLTV={setLTV}
          rating={rating}
          setRating={setRating}
          sort={sort}
          setSort={setSort}
          phone={phone}
          setPhone={setPhone}
          department={department}
          setDepartment={setDepartment}
        />
        <Clients
          customers={customers}
          setCustomers={setCustomers}
          page={page}
          setPage={setPage}
        />
      </Box>
    </>
  )
}
