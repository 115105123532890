import React from "react"
import { createBrowserRouter, Outlet } from "react-router-dom"
import "dayjs/locale/ru"
import NotFoundPage from "pages/NotFoundPage"
import SignUp from "pages/auth/SignUp"
import SignIn from "pages/auth/SignIn"
import Feedback from "pages/Feedback"
import Analytics from "pages/Analytics"
import Main from "pages/Main"
import LkMain from "pages/LkMain"
import Operator from "pages/Operator"
import Tasks from "pages/Tasks"
import Restore from "pages/auth/Restore"
import Marketer from "pages/Marketer"
import Stats from "pages/Stats"
import Profile from "pages/Profile"
import Company from "pages/Company"
import ClientCard from "pages/ClientCard"
import ClientsPage from "pages/clients/ui/clients-page"
import LkLayout from "components/LkLayout"
import PrivatePage from "hocs/PrivatePage"

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <section className="page">
        <Outlet />
      </section>
    ),
    children: [
      {
        index: true,
        path: "",
        element: <Main />,
      },
      {
        path: "signin",
        element: <SignIn />,
      },
      {
        path: "restore",
        element: <Restore />,
      },
      {
        path: "signup",
        element: <SignUp />,
      },
    ],
  },
  {
    path: "/u/",
    element: <LkLayout />,
    children: [
      {
        index: true,
        path: "",
        element: (
          <PrivatePage
            allowedPageTypes={[
              "OWNER",
              "MANAGER",
              "OPERATOR",
              "SPECIAL_GUEST",
              "NO_ACCESS",
            ]}
          >
            <LkMain />
          </PrivatePage>
        ),
      },
      {
        path: "company",
        element: (
          <PrivatePage allowedPageTypes={["OWNER"]}>
            <Company />
          </PrivatePage>
        ),
      },
      {
        path: "operator",
        element: (
          <PrivatePage
            allowedPageTypes={["OWNER", "OPERATOR", "MANAGER", "EMPLOYEE"]}
          >
            <Operator />
          </PrivatePage>
        ),
      },
      {
        path: "clients",
        element: (
          <PrivatePage
            allowedPageTypes={["OWNER", "OPERATOR", "MANAGER", "EMPLOYEE"]}
          >
            <ClientsPage />
          </PrivatePage>
        ),
      },
      {
        path: "profile",
        element: (
          <PrivatePage
            allowedPageTypes={["OWNER", "OPERATOR", "MANAGER", "EMPLOYEE"]}
          >
            <Profile />
          </PrivatePage>
        ),
      },
      {
        path: "stats",
        element: (
          <PrivatePage allowedPageTypes={["OWNER", "MANAGER"]}>
            <Stats />
          </PrivatePage>
        ),
      },
      {
        path: "analytics",
        element: (
          <PrivatePage allowedPageTypes={["OWNER", "MANAGER", "SPECIAL_GUEST"]}>
            <Analytics />
          </PrivatePage>
        ),
      },
      {
        path: "feedback",
        element: (
          <PrivatePage allowedPageTypes={["OWNER", "MANAGER", "EMPLOYEE"]}>
            <Feedback />
          </PrivatePage>
        ),
      },
      {
        path: "tasks",
        element: (
          <PrivatePage allowedPageTypes={["OWNER", "MANAGER", "EMPLOYEE"]}>
            <Tasks />
          </PrivatePage>
        ),
      },
      {
        path: "marketer",
        element: (
          <PrivatePage allowedPageTypes={["OWNER", "MANAGER"]}>
            <Marketer />
          </PrivatePage>
        ),
      },
      {
        path: "client/:phone",
        element: (
          <PrivatePage allowedPageTypes={["OWNER", "MANAGER"]}>
            <ClientCard />
          </PrivatePage>
        ),
      },
    ],
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
])
