import { createTheme } from "@mui/material"

export const theme = createTheme({
  components: {
    MuiSkeleton: {
      defaultProps: {
        animation: "wave",
      },
    },
  },
  palette: {
    // mode: "dark",
    primary: {
      light: "#4BF5CD",
      main: "#16C098",
      dark: "#009B73",
      contrastText: "#fff",
    },
    secondary: {
      light: "#d86070",
      main: "#A72335",
      dark: "#811c2c",
      contrastText: "#fff",
    },
    other: {
      light: "#FFD946",
      main: "#ffbb28",
      dark: "#E19D0A",
      contrastText: "#fff",
    },
    green: {
      main: "#16C099",
      contrastText: "#fff",
    },
    red: {
      main: "#A82336",
      dark: "#791e2b",
      contrastText: "#fff",
    },
    yellow: {
      main: "#E0D100",
      contrastText: "#fff",
    },
    purple: {
      main: "#7051E3",
      contrastText: "#fff",
    },
    orange: {
      main: "#D88359",
      contrastText: "#fff",
    },
    gray: {
      main: "#7390B8",
      contrastText: "#fff",
    },
    blue: {
      main: "#4174D9",
      dark: "#2356BB",
      contrastText: "#fff",
    },
    brown: {
      main: "#6A4A3A",
      dark: "#4C3228",
      contrastText: "#fff",
    },
    pink: {
      main: "#E44B8D",
      dark: "#A53E76",
      contrastText: "#fff",
    },
    turquoise: {
      main: "#40E0D0",
      dark: "#008080",
    },
  },
})
