import { useEffect, useState } from "react"
import { useAuthContext } from "../hocs/AuthProvider"
import { useSnackBarCustom } from "./useSnackbarCustom"

const getCustomersRequest = async (headers, showSnackbar, startDate, endDate, page, LTV, rating, sort, phone, department) => {
  let params = new URLSearchParams({
    start_date: startDate.format('YYYY-MM-DD'),
    end_date: endDate.format('YYYY-MM-DD'),
    lifetime_value_from: LTV[0],
    lifetime_value_to: LTV[1],
    rating_from: rating[0],
    rating_to: rating[1],
    page: page,
    phone: phone,
    page_size: 10,
    order_by_visits_frequency: ({1: undefined, 2: 1, 3: 0})[sort] ?? undefined
  })
  if (department !== -1){
    params.append('department', department)
  }
  const res = await fetch(process.env.REACT_APP_API_URL + "customers/list?" + params.toString(), {
    headers: headers,
    method: "GET",
  }).catch((e) => showSnackbar("Не удалось загрузить"))
  if (res.status === 403) {
    showSnackbar("Недостаточно прав доступа")
    return false
  }
  if (res?.ok && res.ok) {
    const resData = await res.json()
    return resData
  }
  showSnackbar("Не удалось загрузить")
  return false
}

export default function useCustomers(startDate, endDate, page, LTV, rating, sort, phone, department) {
  const { getAuthHeader } = useAuthContext()
  const { showSnackbar } = useSnackBarCustom()
  const [customers, setCustomers] = useState(false)

  useEffect(() => {
    setCustomers(false)
    const load = async () => {
      const headers = await getAuthHeader()
      const customers = await getCustomersRequest(headers, showSnackbar, startDate, endDate, page, LTV, rating, sort, phone, department)
      setCustomers(customers)
    }
    load()
  }, [startDate, endDate, page, LTV, rating, sort, phone, department])

  return { customers, setCustomers }
}
